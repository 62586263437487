<template>
  <a :href="url" class="simple-btn">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: null,
      required: true
    }
  },
  name: 'MyLink'
}
</script>

<style scoped></style>
